export const environment = {
  production: true,
  apiUrl: {
    // reports: 'http://35.154.250.201/adminTemplate/public/api/',
    // master: 'http://35.154.250.201/adminTemplate/public/api/',
    reports: 'https://frontend.mypeerformance.com:8080/api/',
    master: 'https://frontend.mypeerformance.com:8080/api/',
  },
  socketUrl: 'http://93.188.167.68:4500',
  name: 'prod'
};