import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    private data: any = {}

    setOption(option) {
        this.data = option;
        if (this.data.isPlan) {
            sessionStorage.setItem('userData', JSON.stringify(this.data));
        }
        else {
            sessionStorage.setItem('userData', JSON.stringify(this.data));
        }
    }
    getOption() {
        if (this.data.isSelect) {
            console.log(this.data)
            return this.data = {}
        }
        if (this.data.isPlan) {
            return this.data = JSON.parse(sessionStorage.getItem('planData'))
        }
        else if (sessionStorage.userData) {
            return this.data = JSON.parse(sessionStorage.getItem('userData'))
        }
        else {
            return this.data;
        }
    }


    constructor() { }
}
