import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { Roles } from "../models/roles.model";
import { Category } from "../models/category.model";
import { Tag } from "../models/tag.model";

import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { id } from "date-fns/locale";
import { User } from "../models/user.model";
import { AuthService } from "./auth.service";
import { AppLoaderService } from "./app-loader/app-loader.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  root = environment.apiUrl.reports;
  userResponse: any;
  usersData: any;
  categoryResponse: any;
  tagResponse: any;
  token: string = null;
  isLoading: boolean;
  userToken: string;
  constructor(
    private http: HttpClient,
    private toast: ToastrService,
    private route: Router,
    private auth: AuthService,
    private loader: AppLoaderService
  ) {}
  // --------------------access token------------------------
  getHeaders() {
    this.token = localStorage.getItem("token");
    console.log("apiToken", this.token);
    let header;
    if (this.token != "") {
      header = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "x-access-token": this.token,
        }),
      };
    } else {
      console.log("token not found");
    }
    return header;
  }
  // header = {
  //     headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         'x-access-token': this.token
  //     })

  // };

  login(model): Observable<User> {
    const subject = new Subject<User>();
    this.http
      .post(`${this.root}/users/adminlogin`, model)
      .subscribe((responseData: any) => {
        subject.next(responseData);
        (error) => {
          subject.next(error.error);
          if (error.status === 401) {
            // this.toast.error(error)
            console.log("errr", error);
            // this.auth.logout()
          }
        };
      });
    return subject.asObservable();
  }
  addAdmins(data): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http
      .post(`${this.root}/admins/create`, data, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          if (responseData.isSuccess === true) {
            subject.next(responseData);
            this.toast.success(responseData.message);
            this.route.navigate(["tables/filter"]);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }
  addUsers(data): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http.post(`${this.root}create`, data, this.getHeaders()).subscribe(
      (responseData: any) => {
        this.loader.close();
        console.log("res", responseData);
        if (responseData.status == 1) {
          subject.next(responseData);
          this.toast.success(responseData.message);
          this.route.navigate(["tables/user"]);
        }
      },
      (error) => {
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
    return subject.asObservable();
  }
  getAdmins(): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http.get(`${this.root}/admins/getAdmins`, this.getHeaders()).subscribe(
      (responseData: any) => {
        this.loader.close();
        if (responseData.isSuccess === true) {
          subject.next(responseData);
          this.toast.success(responseData.message);
        }
      },
      (error) => {
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
    return subject.asObservable();
  }

  getMyPost(id): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http.get(`${this.root}/posts/myPosts/${id}`).subscribe(
      (responseData: any) => {
        this.loader.close();
        if (responseData.isSuccess === true) {
          subject.next(responseData);
          this.toast.success(responseData.message);
        }
      },
      (error) => {
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
    return subject.asObservable();
  }

  getUserById(id): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http
      .get(`${this.root}/users/getById/${id}`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          if (responseData.isSuccess === true) {
            subject.next(responseData.items);
            this.toast.success(responseData.message);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  getUsers(): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http.get(`${this.root}users/getList`, this.getHeaders()).subscribe(
      (responseData: any) => {
        console.log("res", responseData);
        this.loader.close();
        // if (responseData.status == 1) {
        subject.next(responseData);
        this.toast.success(responseData.message);
        // }
      },
      (error) => {
        this.loader.close();
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
    return subject.asObservable();
  }

  userLeaderBoard(type): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http
      .post(`${this.root}leaderboard`, type, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          console.log("res", responseData);
          this.loader.close();
          if (responseData.status == 1) {
            subject.next(responseData);
            this.toast.success(responseData.message);
          }
        },
        (error) => {
          this.loader.close();
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  getHostUsers(): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http.get(`${this.root}getHostUsers`, this.getHeaders()).subscribe(
      (responseData: any) => {
        console.log("res", responseData);
        this.loader.close();
        if (responseData.status == 1) {
          subject.next(responseData);
          this.toast.success(responseData.message);
        }
      },
      (error) => {
        this.loader.close();
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
    return subject.asObservable();
  }
  getCurrentUser(id): Observable<User> {
    const subject = new Subject<User>();
    this.http
      .get(`${this.root}users/getById/${id}`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          console.log("CurrentUser", responseData);
          // if (responseData.status == 1) {
          subject.next(responseData.data);

          // }
        },
        (error) => {
          subject.next(error.error);
          // this.toast.error(error.error.error)
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            // this.toast.error(error.error)
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  getFollowers(id): Observable<User> {
    const subject = new Subject<User>();
    this.http
      .get(`${this.root}/following/getFollowers/${id}`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          if (responseData.isSuccess === true) {
            subject.next(responseData.data);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  getFollowing(id): Observable<User> {
    const subject = new Subject<User>();
    this.http
      .get(`${this.root}/following/getFollowing/${id}`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          if (responseData.isSuccess === true) {
            subject.next(responseData.data);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  getLikes(id): Observable<User> {
    const subject = new Subject<User>();
    this.http
      .get(`${this.root}/likes/getLikes/${id}`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          if (responseData.isSuccess === true) {
            subject.next(responseData.data);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  deletePost(id): Observable<User> {
    const subject = new Subject<User>();
    this.http
      .delete(`${this.root}/posts/delete/${id}`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          if (responseData.isSuccess === true) {
            subject.next(responseData.data);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  deleteComment(id): Observable<User> {
    const subject = new Subject<User>();
    this.http
      .delete(`${this.root}/comments/delete/${id}`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          if (responseData.isSuccess === true) {
            subject.next(responseData.data);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  getComments(id): Observable<User> {
    const subject = new Subject<User>();
    this.http
      .get(`${this.root}/comments/getCount/${id}`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          if (responseData.isSuccess === true) {
            subject.next(responseData.data);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  addRoles(data): Observable<Roles[]> {
    const subject = new Subject<Roles[]>();
    this.loader.open();
    this.http
      .post(`${this.root}/roles/create`, data, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          if (responseData.isSuccess === true) {
            subject.next(responseData);
            this.route.navigate(["tables/permissions"]);
            this.toast.success(responseData.message);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  getRoles(): Observable<Roles[]> {
    const subject = new Subject<Roles[]>();
    this.http.get(`${this.root}/roles/getAll`).subscribe(
      (response: any) => {
        subject.next(response.data);
      },
      (error) => {
        subject.next(error.error);

        if (error.status === 401) {
          // this.toast.error(error)
          console.log("errr", error);
          // this.auth.logout()
        }
      }
    );
    return subject.asObservable();
  }

  uploadUserImage(model): Observable<any> {
    const subject = new Subject<any>();
    this.loader.open();
    this.http
      .post(`${this.root}uploadProfileImage`, model, {
        headers: null,
      })
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          console.log("res", responseData);
          if (responseData.status == 1) {
            subject.next(responseData);
            this.toast.success(responseData.message);
            this.route.navigate(["tables/user"]);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 0) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  uploadMultipleImages(model): Observable<any> {
    const subject = new Subject<any>();
    this.loader.open();
    this.http
      .post(`${this.root}/images/uploadMultiple`, model, {
        headers: null,
      })
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          if (responseData.isSuccess === true) {
            subject.next(responseData);
            this.toast.success(responseData.data);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  deleteUser(id): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http
      .delete(`${this.root}users/delete/${id}`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          if (responseData) {
            subject.next(responseData);
            console.log(responseData);
            this.toast.success(responseData.message);
          //   this.route
          //     .navigateByUrl("/tables", { skipLocationChange: true })
          //     .then(() => {
          //       this.route.navigate(["tables/user"]);
          //       localStorage.removeItem("userToken");
          //     });
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  // -------------------------update user-------------------------------------------

  updateUser(id, data): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http
      .put(`${this.root}users/update/${id}`, data, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          console.log("res", responseData);
          // if (responseData.status == 1) {
          subject.next(responseData);
          // this.toast.success(responseData.message)
          this.route.navigate(["tables/user"]);
          // }
        },
        (error) => {
          subject.next(error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  adminResetPassword(id, model): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http
      .put(`${this.root}/users/changePassword/${id}`, model, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          if (responseData.isSuccess === true) {
            subject.next(responseData);
            this.toast.success(responseData.message);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  resetUserPassword(id, model): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http
      .put(`${this.root}/users/changePassword/${id}`, model, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          if (responseData.isSuccess === true) {
            subject.next(responseData);
            this.toast.success(responseData.message);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  updateAdmin(id, data): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http
      .put(`${this.root}/users/update/${id}`, data, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          subject.next(responseData);
          this.route.navigate(["tables/filter"]);
        },
        (error) => {
          subject.next(error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  getOldChat(_id, pageNo, pageSize): Observable<any[]> {
    const subject = new Subject<any[]>();
    // this.loader.open();
    this.http
      .get(
        `${this.root}/conversations/getOldChat?room_id=${_id}&pageNo=${pageNo}&pageSize=${pageSize}`,
        this.getHeaders()
      )
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          if (responseData.isSuccess === true) {
            subject.next(responseData.items);
            //  this.toast.success(responseData.message)
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.loader.close();
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  removeImage(id, imageId, image): Observable<any> {
    const subject = new Subject<any>();
    this.loader.open();
    this.http
      .put(
        `${this.root}/images/remove/?id=${id}&imageId=${imageId}&image=${image}`,
        {
          headers: null,
        }
      )
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          if (responseData.isSuccess === true) {
            subject.next(responseData);
            console.log(responseData.data);
            this.toast.success(responseData.data);
            this.route
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() => {
                this.route.navigate(["profile/settings", id]);
              });
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  searchUser(key): Observable<User> {
    const subject = new Subject<User>();
    console.log("key", key);
    this.http
      .get(`${this.root}/users/search?name=${key}`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          subject.next(responseData.data);
        },
        (error) => {
          subject.next(error.error);
        }
      );

    return subject.asObservable();
  }

  // ---------------------add plans -----------------------
  addPlans(data): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.loader.open();
    this.http.post(`${this.root}addPlans`, data).subscribe(
      (responseData: any) => {
        this.loader.close();
        console.log("res", responseData);
        if (responseData.status == 1) {
          subject.next(responseData);
          this.toast.success(responseData.message);
          this.route.navigate(["tables/plan"]);
        }
      },
      (error) => {
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
    return subject.asObservable();
  }
  // ---------------------add plans -----------------------
  updatePlan(data): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.loader.open();
    this.http.post(`${this.root}updatePlan`, data).subscribe(
      (responseData: any) => {
        this.loader.close();
        console.log("res", responseData);
        if (responseData.status == 1) {
          subject.next(responseData);
          this.toast.success(responseData.message);
          this.route.navigate(["tables/plan"]);
        }
      },
      (error) => {
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
    return subject.asObservable();
  }
  getPlans(): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.loader.open();
    this.http.post(`${this.root}getPlans`, this.getHeaders()).subscribe(
      (responseData: any) => {
        console.log("res", responseData);
        this.loader.close();
        if (responseData.status == 1) {
          subject.next(responseData);
          this.route.navigate(["tables/plan"]);
          this.toast.success(responseData.message);
        }
      },
      (error) => {
        this.loader.close();
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
    return subject.asObservable();
  }
  addGifts(model: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.loader.open();
    this.http.post(`${this.root}addGift`, model).subscribe(
      (responseData: any) => {
        this.loader.close();
        console.log("res", responseData);
        if (responseData.status == 1) {
          subject.next(responseData);
          this.toast.success(responseData.message);
          this.route.navigate(["tables/gift"]);
        }
      },
      (error) => {
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
    return subject.asObservable();
  }

  getGifts(): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.loader.open();
    this.http.post(`${this.root}getGifts`, this.getHeaders()).subscribe(
      (responseData: any) => {
        console.log("res", responseData);
        this.loader.close();
        if (responseData.status == 1) {
          subject.next(responseData);
          this.route.navigate(["tables/gift"]);
          this.toast.success(responseData.message);
        }
      },
      (error) => {
        this.loader.close();
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
    return subject.asObservable();
  }

  // ---------------------delete plan----------------
  deletePlan(id): Observable<User> {
    const subject = new Subject<User>();
    this.http
      .delete(`${this.root}deletePlans?id=${id}`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          console.log("res", responseData);
          if (responseData.status == 1) {
            subject.next(responseData);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  // --------------------- get media----------------

  getMedia(id): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http.post(`${this.root}getMedia`, id, this.getHeaders()).subscribe(
      (responseData: any) => {
        this.loader.close();
        console.log("ress media", responseData);
        if (responseData.status == 1) {
          subject.next(responseData);
          this.toast.success(responseData.message);
        }
      },
      (error) => {
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
    return subject.asObservable();
  }
  // --------------------- get Categories----------------

  getCategories(body:any): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.loader.open();
    this.http.post(`${this.root}categories/getAll`,body, this.getHeaders()).subscribe(
      (responseData: any) => {
        console.log("res", responseData);
        this.loader.close();
        // if (responseData.status == 1) {
        subject.next(responseData);
        // this.toast.success('Get All categories ')
        // }
      },
      (error) => {
        this.loader.close();
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
    return subject.asObservable();
  }
  getRegiontypes(): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.loader.open();
    this.http
      .get(`${this.root}regiontype/getList`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          console.log("res", responseData);
          this.loader.close();
          // if (responseData.status == 1) {
          subject.next(responseData);
          // this.toast.success('Get All categories ')
          // }
        },
        (error) => {
          this.loader.close();
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }
  getRegions(): Observable<any[]> {
    const subject = new Subject<any[]>();
    // this.loader.open();
    this.http
      .get(`${this.root}regions/getRegionslist`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          console.log("res", responseData);
          // this.loader.close();
          // if (responseData.status == 1) {
          subject.next(responseData);
          // this.toast.success('Get All categories ')
          // }
        },
        (error) => {
          // this.loader.close();
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }
  getRegionById(id: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    // this.loader.open();
    this.http
      .get(`${this.root}regions/getById/${id}`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          console.log("res", responseData);
          // this.loader.close();
          // if (responseData.status == 1) {
          subject.next(responseData);
          // this.toast.success('Get All categories ')
          // }
        },
        (error) => {
          // this.loader.close();
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          // this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }
  uploadCagegory(formdata: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.loader.open();
    const headers = { "x-access-token": this.token };
    var config = {
      headers: new HttpHeaders({
        "x-access-token": this.token,
      }),
    };
    this.http.post(`${this.root}categories/import`, formdata, config).subscribe(
      (responseData) => {
        this.userResponse = responseData;
        subject.next(this.userResponse);
        this.loader.close();
        console.log("Lists", this.userResponse);
      },
      (error) => {
        subject.next(error);
        this.loader.close();
      }
    );
    return subject.asObservable();
  }
  addCagegory(model: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.loader.open();
    this.http
      .post(`${this.root}categories/createCategory`, model, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          console.log("res", responseData);
          // if (responseData.status == 1) {
          subject.next(responseData);
          this.toast.success("Category create sucess");
          this.route.navigate(["tables/categories"]);
          // }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }
  deleteCategory(id): Observable<User> {
    const subject = new Subject<User>();
    this.http
      .delete(`${this.root}categories/delete/${id}`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          if (responseData.isSuccess === true) {
            subject.next(responseData.data);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);

            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }
  updateCategory(id, data): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.loader.open();
    this.http
      .put(`${this.root}categories/update/${id}`, data, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          console.log("res", responseData);
          // if (responseData.status == 1) {
          subject.next(responseData);
          this.toast.success(responseData.message);
          this.route.navigate(["tables/categories"]);
          // }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }

  addRegiontype(model: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.loader.open();
    this.http
      .post(`${this.root}regiontype/create`, model, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          console.log("res", responseData);
          // if (responseData.status == 1) {
          subject.next(responseData);
          this.toast.success("Regiontype create sucess");
          this.route.navigate(["tables/regionTypes"]);
          // }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }
  updateRegiontype(id, data): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.loader.open();
    this.http
      .put(`${this.root}regiontype/update/${id}`, data, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          console.log("res", responseData);
          // if (responseData.status == 1) {
          subject.next(responseData);
          this.toast.success(responseData.message);
          this.route.navigate(["tables/regionTypes"]);
          // }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }
  deleteRegiontype(id): Observable<User> {
    const subject = new Subject<User>();
    this.http
      .delete(`${this.root}categories/delete/${id}`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          if (responseData.isSuccess === true) {
            subject.next(responseData.data);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);

            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }
  addRegion(model: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.loader.open();
    this.http
      .post(`${this.root}regions/createRegion`, model, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          console.log("res", responseData);
          // if (responseData.status == 1) {
          subject.next(responseData);
          this.toast.success("Region create sucess");
          this.route.navigate(["tables/regions"]);
          // }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }
  updateRegion(id, data): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.loader.open();
    this.http
      .put(`${this.root}regions/update/${id}`, data, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          console.log("res", responseData);
          // if (responseData.status == 1) {
          subject.next(responseData);
          this.toast.success(responseData.message);
          this.route.navigate(["tables/regions"]);
          // }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }
  deleteRegion(id): Observable<User> {
    const subject = new Subject<User>();
    this.http
      .delete(`${this.root}regions/delete/${id}`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          if (responseData.isSuccess === true) {
            subject.next(responseData.data);
          }
        },
        (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);

            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }
  getSuggestedstudies(): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http
      .get(`${this.root}suggeststudy/getlist`, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          console.log("res", responseData);
          this.loader.close();
          // if (responseData.status == 1) {
          subject.next(responseData);
          this.toast.success(responseData.message);
          // }
        },
        (error) => {
          this.loader.close();
          subject.next(error.error);
          this.toast.error(error.error.error);
          console.log("errr", error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }
  updatesuggestStudies(id, data): Observable<User[]> {
    const subject = new Subject<User[]>();
    // this.loader.open();
    this.http
      .put(`${this.root}suggeststudy/update/${id}`, data, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          // this.loader.close();
          console.log("res", responseData);
          // if (responseData.status == 1) {
          subject.next(responseData);
          // this.toast.success(responseData.message)
          this.route.navigate(["tables/suggested-studies"]);
          // }
        },
        (error) => {
          subject.next(error.error);
          // this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }
  updateExampleStudies(id, data): Observable<User[]> {
  const subject = new Subject<User[]>();
  this.loader.open();
  this.http
    .put(`${this.root}examplestudies/update/${id}`, data, this.getHeaders())
    .subscribe(
      (responseData: any) => {
        this.loader.close();
        console.log("res", responseData);
        // if (responseData.status == 1) {
        subject.next(responseData);
        // this.toast.success(responseData.message)
        this.route.navigate(["tables/example-studies"]);
        // }
      },
      (error) => {
        subject.next(error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
  return subject.asObservable();
}
  updateStudies(id, data): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http
      .put(`${this.root}studies/update/${id}`, data, this.getHeaders())
      .subscribe(
        (responseData: any) => {
          this.loader.close();
          console.log("res", responseData);
          // if (responseData.status == 1) {
          subject.next(responseData);
          // this.toast.success(responseData.message)
          this.route.navigate(["tables/studies"]);
          // }
        },
        (error) => {
          subject.next(error.error);
          this.loader.close();
          if (error.status === 401) {
            this.toast.error(error.error);
            console.log("errr", error.error);
            // this.auth.logout()
          }
        }
      );
    return subject.asObservable();
  }
  getExampleStudies(): Observable<User[]> {

    const subject = new Subject<User[]>();
    this.loader.open();
    this.http.get(`${this.root}examplestudies/getlist`, this.getHeaders()).subscribe(
      (responseData: any) => {
        console.log("res", responseData);
        this.loader.close();
        // if (responseData.status == 1) {
        subject.next(responseData);
        this.toast.success(responseData.message);
        // }
      },
      (error) => {
        this.loader.close();
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
    return subject.asObservable();
  }
  getStudies(): Observable<User[]> {
    const subject = new Subject<User[]>();
    this.loader.open();
    this.http.get(`${this.root}studies/getlist`, this.getHeaders()).subscribe(
      (responseData: any) => {
        console.log("res", responseData);
        this.loader.close();
        // if (responseData.status == 1) {
        subject.next(responseData);
        this.toast.success(responseData.message);
        // }
      },
      (error) => {
        this.loader.close();
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
    return subject.asObservable();
  }
  addStudies(model:any): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.loader.open();
    this.http.post(`${this.root}studies/create`, model,this.getHeaders()).subscribe((responseData: any) => {
        this.loader.close();
        console.log('res', responseData)
        // if (responseData.status == 1) {
            subject.next(responseData);
            this.toast.success('Sutdy create success')
            // this.route.navigate(['tables/studies']);
        // }
    },
        (error) => {
            subject.next(error.error);
            this.toast.error(error.error.error)
            console.log('errr', error.error)
            this.loader.close();
            if (error.status === 401) {
                this.toast.error(error.error)
                console.log('errr', error.error)
                // this.auth.logout()
            }
        }

    );
    return subject.asObservable();
}

deleteStudy(id): Observable<User> {
  const subject = new Subject<User>();
  this.http
    .delete(`${this.root}studies/delete/${id}`, this.getHeaders())
    .subscribe(
      (responseData: any) => {
        if (responseData.isSuccess === true) {
          subject.next(responseData.data);
        }
      },
      (error) => {
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);

          // this.auth.logout()
        }
      }
    );
  return subject.asObservable();
}
addExampleStudies(model:any): Observable<any[]> {
  const subject = new Subject<any[]>();
  this.loader.open();
  this.http.post(`${this.root}examplestudies/create`, model,this.getHeaders()).subscribe((responseData: any) => {
      this.loader.close();
      console.log('res', responseData)
      // if (responseData.status == 1) {
          subject.next(responseData);
          this.toast.success('Sutdy create success')
          // this.route.navigate(['tables/studies']);
      // }
  },
      (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error)
          console.log('errr', error.error)
          this.loader.close();
          if (error.status === 401) {
              this.toast.error(error.error)
              console.log('errr', error.error)
              // this.auth.logout()
          }
      }

  );
  return subject.asObservable();
}
deleteExampleStudy(id): Observable<User> {
  const subject = new Subject<User>();
  this.http
    .delete(`${this.root}examplestudies/delete/${id}`, this.getHeaders())
    .subscribe(
      (responseData: any) => {
        if (responseData.isSuccess === true) {
          subject.next(responseData.data);
        }
      },
      (error) => {
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);

          // this.auth.logout()
        }
      }
    );
  return subject.asObservable();
}
deleteQuicklink(id): Observable<User> {
  const subject = new Subject<User>();
  this.http
    .delete(`${this.root}quicklinks/delete/${id}`, this.getHeaders())
    .subscribe(
      (responseData: any) => {
        if (responseData.isSuccess === true) {
          subject.next(responseData.data);
        }
      },
      (error) => {
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);

          // this.auth.logout()
        }
      }
    );
  return subject.asObservable();
}
addQuickLink(model:any): Observable<any[]> {
  const subject = new Subject<any[]>();
  this.loader.open();
  this.http.post(`${this.root}quicklinks/create`, model,this.getHeaders()).subscribe((responseData: any) => {
      this.loader.close();
      console.log('res', responseData)
      // if (responseData.status == 1) {
          subject.next(responseData);
          this.toast.success(responseData.message)
          // this.route.navigate(['tables/studies']);
      // }
  },
      (error) => {
          subject.next(error.error);
          this.toast.error(error.error.error)
          console.log('errr', error.error)
          this.loader.close();
          if (error.status === 401) {
              this.toast.error(error.error)
              console.log('errr', error.error)
              // this.auth.logout()
          }
      }

  );
  return subject.asObservable();
}
getQuickLinks(): Observable<User[]> {
  const subject = new Subject<User[]>();
  this.loader.open();
  this.http.get(`${this.root}quicklinks/getlist`, this.getHeaders()).subscribe(
    (responseData: any) => {
      console.log("res", responseData);
      this.loader.close();
      // if (responseData.status == 1) {
      subject.next(responseData);
      this.toast.success(responseData.message);
      // }
    },
    (error) => {
      this.loader.close();
      subject.next(error.error);
      this.toast.error(error.error.error);
      console.log("errr", error.error);
      this.loader.close();
      if (error.status === 401) {
        this.toast.error(error.error);
        console.log("errr", error.error);
        // this.auth.logout()
      }
    }
  );
  return subject.asObservable();
}
updateQuickLink(id, data): Observable<User[]> {
  const subject = new Subject<User[]>();
  this.loader.open();
  this.http
    .put(`${this.root}quicklinks/update/${id}`, data, this.getHeaders())
    .subscribe(
      (responseData: any) => {
        this.loader.close();
        console.log("res", responseData);
        // if (responseData.status == 1) {
        subject.next(responseData);
        // this.toast.success(responseData.message)
        this.route.navigate(["tables/quickLinks"]);
        // }
      },
      (error) => {
        subject.next(error.error);
        this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
  return subject.asObservable();
}

categorySearch(model: any): Observable<any[]> {
  const subject = new Subject<any[]>();
  // this.loader.open();
  this.http
    .post(`${this.root}categories/search`, model, this.getHeaders())
    .subscribe(
      (responseData: any) => {
        // this.loader.close();
        console.log("res", responseData);
        // if (responseData.status == 1) {
        subject.next(responseData);
        // this.toast.success("Regiontype create sucess");
        // this.route.navigate(["tables/regionTypes"]);
        // }
      },
      (error) => {
        subject.next(error.error);
        this.toast.error(error.error.error);
        console.log("errr", error.error);
        // this.loader.close();
        if (error.status === 401) {
          this.toast.error(error.error);
          console.log("errr", error.error);
          // this.auth.logout()
        }
      }
    );
  return subject.asObservable();
}
uploadIcons(model: any): Observable<HttpEvent<any>> {
  console.log("model ", model);
    // this.loader.open();
    const headers = { "x-access-token": this.token };
    const config = { headers: headers };
  const subject = new Subject<any>();
  this.http.post(`${this.root}gallery/uploadMultiple`, model,config).subscribe(
    (response) => {
      console.log('image ',response)
      // this.toast.success("Successfully upload image");
      subject.next(response);
      //  this.loader.close();

      // console.log('response', response);
    },
    (error) => {
      console.log("error", error);
    //  this.loader.close();

      subject.next(error.error);
    }
  );
  return subject.asObservable();
}
removeIcon(id: any): Observable<any> {
  // this.loader.open();
  const subject = new Subject<any>();
  const headers = { "x-access-token": this.token };
    const config = { headers: headers };
  this.http
    .delete(
      `${this.root}gallery/delete/${id}`,config
      
    )
    .subscribe(
      (responseData: any) => {
        console.log("remove img res", responseData);
        this.toast.success("Successfully Delete Image");
        subject.next(responseData);
        // this.loader.close();
      },
      (error) => {
        // this.loader.close();
        subject.next(error);
        this.toast.error(error);
      }
    );
  return subject.asObservable();
}
getImages(): Observable<any> {
  this.loader.open();
  const subject = new Subject<any>();
  this.http
    .get(`${this.root}gallery/getAll`, this.getHeaders())
    .subscribe(
      (response: any) => {
        console.log("get images res", response);
        this.userResponse = response;
        subject.next(this.userResponse);
        this.loader.close();
        // this.toastr.success("Get Images Successfully");
      },
      (error) => {
        subject.next(error.error);
        this.loader.close();
        this.toast.error(error);
      }
    );
  return subject.asObservable();
}
}
