<footer class="main-footer">
    <div class="container-dynamic d-flex">
        <!-- <a
      mat-raised-button
      color="accent"
      href="http://1.envato.market/JN1r7"
      >Buy Egret</a
    > -->
        <!-- <span class="m-auto"></span> Design & Developed By MSPL -->
        <!-- <a href="https://ui-lib.com"> UI Lib</a> -->
    </div>
</footer>