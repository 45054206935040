// import { Pipe, PipeTransform } from '@angular/core';

// @Pipe({
//   name: 'safeHtml'
// })
// export class SafeHtmlPipe implements PipeTransform {

//   transform(value: unknown, ...args: unknown[]): unknown {
//     return null;
//   }

// }
// import { Pipe, PipeTransform } from '@angular/core';

// @Pipe({
//   name: 'safeHtml'
// })
// export class SafeHtmlPipe implements PipeTransform {

//   transform(value: unknown, ...args: unknown[]): unknown {
//     return null;
//   }

// }
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


//   transform(value: unknown, ...args: unknown[]): unknown {
//     return null;
//   }

// }
@Pipe({
   name: 'safeHtml'
 })
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitizer:DomSanitizer){}

  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}